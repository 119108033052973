import { createSlice } from "@reduxjs/toolkit"

const initial_state = {
  imBusy: true,
}

const { actions, reducer } = createSlice({
  name: "App",
  initialState: initial_state,
  reducers: {
    toggle(state, payload) {
      state.imBusy = typeof payload === "boolean" ? payload : !state.imBusy
    },
  },
})

export { actions, reducer, initial_state }
